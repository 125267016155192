import React, { createContext, useContext } from 'react'
import { type ApiInputSchema } from '@monorepo/api'
import { produce } from 'immer'
import { atom, useAtom } from 'jotai'
import { differenceBy, unionBy, xor, xorBy } from 'lodash'
import { formatQuery, RuleGroupType } from 'react-querybuilder'
import { createTrackedSelector } from 'react-tracked'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { CATEGORIES, ETAILERS } from '@/constants'
import { createSelectors } from '@/util/create-selectors'
import { Countries } from '@/util/getCurrency'
import { createPersistentStorage } from '@/util/zustand-middleware'
import { advancedSearchFields } from './_components/AdvancedSearch'

function groupBy<T extends Record<string, any>, K extends string>(
  list: T[],
  key: K
): Record<string, Omit<T, K>[]> {
  return list.reduce((previous, currentItem) => {
    const groupKey = currentItem[key]
    const { [key]: _, ...rest } = currentItem
    ;(previous[groupKey] = previous[groupKey] || []).push(rest)
    return previous
  }, {} as Record<string, Omit<T, K>[]>)
}

export const FILTERS_MAP = {
  c4: 'Categories & Product Types',
  product_type: 'Categories & Product Types',
  domain: 'Etailers',
  price: 'Price Range',

  ingredient: 'ingredients',
  ingredient_group: 'ingredients',
  for_whom: 'For Whom',
  brand: 'brands',
  effect: 'effects',
  attribute: 'attributes',
  concern: 'concerns',
  certificate: 'certificates',
  size: 'sizes',
  ratings_tag: 'ratings',
} as const

type FilterIdType = keyof typeof FILTERS_MAP
type FilterLabel = (typeof FILTERS_MAP)[FilterIdType]

export const invertedFiltersMap = {
  ...Object.keys(FILTERS_MAP).reduce((obj, key) => {
    const value = FILTERS_MAP[key]
    if (obj[value]) {
      obj[value].push(key)
    } else {
      obj[value] = [key]
    }
    return obj
  }, {} as Record<FilterLabel, string[]>),
  etailers: ['domain'],
}

type Vars = ApiInputSchema & { geo: Countries }

export const omitFilterType = (vars: Vars, type: FilterLabel | 'etailers') => {
  if (type === 'etailers') {
    return {
      ...vars,
      domain_tlds: [],
    }
  }
  return {
    ...vars,
    filter_ids: vars.filter_ids?.filter((f) => !invertedFiltersMap[type].includes(f.type)),
  }
}

export const defaultFilters = {
  c2Id: CATEGORIES[0].c2_id,
  geo: 'de' as Countries,
  etailers: [],
  filter_ids: [],
  showNewProducts: false,
  operator: {
    ingredient: 'OR',
    effect: 'OR',
    attribute: 'OR',
    concern: 'OR',
    certificate: 'OR',
  } as Record<string, 'AND' | 'OR'>,
  advancedSearch: null,
  matchPhrase: [],
}

export type FilterType = {
  type: string
  id: string
  name: string
  parentId?: string
  parentName?: string
  value?: number[]
}

interface State {
  c2Id: string
  geo: Countries
  etailers: string[]
  filter_ids: FilterType[]
  showNewProducts: boolean
  operator: Record<string, 'AND' | 'OR'>
  advancedSearch: RuleGroupType | null
  matchPhrase: string[] // list of fields that should have match phrase toggle
}

interface Actions {
  setFilters: (updateFn: (draft: State) => void) => void
  setForWhomFilter: (forWhom: FilterType) => void
  addFilters: (f: FilterType[]) => void
  filterAction: (f: (FilterType & { action: 'remove' | 'add' | 'toggle' })[]) => void
  removeFilters: (f: FilterType[]) => void
  toggleFilters: (f: FilterType[]) => void
  replaceFilters: (f: FilterType[]) => void
  setCountry: (country: string) => void
  resetFilters: () => void
  setShowNewProducts: (showNewProducts: boolean) => void
  setOperator: (type: string, operator: 'AND' | 'OR') => void
  setAdvancedSearch: (query: RuleGroupType | null) => void
  toggleMatchPhrase: (field: string) => void
}

interface ComputedStore {
  vars: Vars
  //   hasFilter: (f: FilterType) => boolean
}

type Store = State & Actions

export const useMIStoreBase = create<Store>()(
  persist(
    immer((set, get) => ({
      ...defaultFilters,

      setFilters: (updateFn) => set(produce(updateFn)),

      setForWhomFilter: (forWhom) => {
        set((state) => {
          const i = state.filter_ids.findIndex((f) => f.type === 'for_whom')
          if (i === -1) {
            state.filter_ids.push(forWhom)
            return
          }
          state.filter_ids.splice(i, 1, forWhom)
        })
      },

      filterAction(f) {
        set((state) => {
          const fActions = groupBy(f, 'action')
          const removed = differenceBy(state.filter_ids, fActions?.remove ?? [], (o) => o.id)
          const added = unionBy(removed, fActions?.add ?? [], (o) => o.id)
          const toggled = xorBy(added, fActions?.toggle ?? [], (o) => o.id)
          state.filter_ids = toggled
        })
      },

      addFilters(f) {
        set((state) => {
          state.filter_ids = unionBy(f, state.filter_ids, (o) => o.id)
        })
      },

      removeFilters(f) {
        set((state) => {
          state.filter_ids = differenceBy(state.filter_ids, f, (o) => o.id)
        })
      },

      toggleFilters(f) {
        set((state) => {
          state.filter_ids = xorBy(state.filter_ids, f, (o) => o.id)
        })
      },

      replaceFilters(f) {
        // replace filters by id
        set((state) => {
          state.filter_ids = f
        })
      },

      setCountry(country) {
        set((state) => {
          state.geo = country as Countries
        })
      },

      setShowNewProducts(showNewProducts) {
        set((state) => {
          state.showNewProducts = showNewProducts
        })
      },

      setOperator(type, operator) {
        set((state) => {
          state.operator[type] = operator
        })
      },

      setAdvancedSearch(query) {
        set((state) => {
          state.advancedSearch = query
        })
      },

      resetFilters: () =>
        set((state) => {
          state.filter_ids = []
          state.advancedSearch = null
        }),

      toggleMatchPhrase(f) {
        set((state) => {
          state.matchPhrase = xor(state.matchPhrase, [f])
        })
      },
    })),
    {
      name: 'mi',
      version: 1,
      storage: createPersistentStorage(),
      partialize: (state) =>
        Object.fromEntries(Object.entries(state).filter(([key]) => !['vars'].includes(key))),
    }
  )
)

function computeState(state: State & Actions): ComputedStore {
  const etailers = state.filter_ids.filter((f) => f.type === 'domain').map((f) => f.id)
  const price = state.filter_ids.find((f) => f.id === 'price')?.value
  const min_price = price?.[0]
  const max_price = price?.[1]
  let mongodb: any
  if (state?.advancedSearch) {
    const mongodbJson = JSON.parse(
      formatQuery(state?.advancedSearch, { format: 'mongodb', fields: advancedSearchFields })
    )
    const defaultKey = Object.keys(mongodbJson)[0] // could be an operator or a field
    if (defaultKey === '$or' || defaultKey === '$and') {
      mongodb = {
        [defaultKey]: mongodbJson[defaultKey].map((obj) => {
          const field = Object.keys(obj)[0]
          obj[field] = { ...obj[field], match_phrase: state.matchPhrase.includes(field) }
          return obj
        }),
      }
    } else {
      mongodb = {
        [defaultKey]: {
          ...mongodbJson[defaultKey],
          match_phrase: state.matchPhrase.includes(defaultKey),
        },
      }
    }
  }
  return {
    vars: {
      c2_id: state.c2Id,
      geo: state.geo,
      domain_tlds: etailers.length > 0 ? etailers : ETAILERS[state.geo],
      min_price: min_price,
      max_price: max_price === Infinity ? undefined : max_price,
      filter_ids: state.filter_ids
        .filter((f) => f.type !== 'domain' && f.type !== 'price')
        .map(({ name, id, type }) =>
          //   type.startsWith('ingredient') ? { id: id.split(':')[1], type } : { id, type }
          ({ id, type })
        ),
      filter_operators: state.operator,
      ...(state.advancedSearch && {
        advanced_search: {
          mongodb: mongodb,
        },
      }),
    },
  }
}

export const useMIStore = createSelectors(useMIStoreBase)

export const useTrackedMIStore = createTrackedSelector(useMIStoreBase)

export const useMIVars = () => useMIStoreBase(computeState)

interface ReviewFilterContext {
  reviewsMin?: number
  setReviewsMin?: (reviewsMin: number) => void
}

export const ReviewFilterContext = createContext<ReviewFilterContext>({} as ReviewFilterContext)

export const ReviewFilterProvider = ({ children }) => {
  const [reviewsMin, setReviewsMin] = React.useState(1)
  return (
    <ReviewFilterContext.Provider value={{ reviewsMin, setReviewsMin }}>
      {children}
    </ReviewFilterContext.Provider>
  )
}

interface RankingFilterContext {
  rankings?: boolean
}

export const RankingFilterContext = createContext<RankingFilterContext>({} as RankingFilterContext)

export const RankingFilterProvider = ({ children }) => {
  return (
    <RankingFilterContext.Provider value={{ rankings: true }}>
      {children}
    </RankingFilterContext.Provider>
  )
}

export const rankingsDomainAtom = atom('douglas_de')

export const useTrackedFilterStore = () => {
  const reviewFilter = useContext(ReviewFilterContext)
  const rankingFilter = useContext(RankingFilterContext)
  const [domain, setDomain] = useAtom(rankingsDomainAtom)

  const {
    vars: { domain_tlds, ...vars },
  } = useMIVars()
  return {
    ...vars,
    ...(rankingFilter?.rankings
      ? {
          //   filter_ids: filter_ids?.filter((filter) =>
          //     ['c4', 'product_type', 'brand'].includes(filter.type)
          //   ),
          geo: 'de' as Countries,
          domain_tlds: domain ? [domain] : [],
        }
      : { domain_tlds }),
    ...(reviewFilter?.reviewsMin && { reviews_min: reviewFilter.reviewsMin }),
  }
}
